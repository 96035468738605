import React, { useState } from 'react'
import styled from 'styled-components'
import logo from '../../assets/img/logo-lg.png'

import { notification } from 'antd';
import PasswordInput from '../../components/Elements/PasswordInput'
import { useDispatch } from 'react-redux'
import { useNavigate, Navigate } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from '../../@types/store'
import { requestAuthInit } from '../../store/auth/auth.actions'
import AuthService from '../../services/auth'
import { LoginParam } from '../../@types/auth'


const Login = () => {

    const navigate = useNavigate();
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            await AuthService.login({
                username: username,
                password: password
            } as LoginParam)
            dispatch(requestAuthInit(true))
            navigate('/users')
        } catch (error: any) {
            notification.error({
                message: 'Error',
                description: error.message ? error.message : "ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้"
            });
        }
    }

    const token = localStorage.getItem('jwt')
    if (token) {
        return <Navigate to="/users" replace />
    }

    return (
        <Wrapper>
            <SignIn>
                <form onSubmit={handleSubmit}>
                    <img className="mb-5 d-block mx-auto" src={logo} alt="logo DooSlip" width="180" />
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="floatingInput" placeholder="Username" onChange={e => setUsername(e.target.value)} value={username} required />
                        <label>ชื่อผู้ใช้งาน</label>
                    </div>
                    <PasswordInput
                        password={password}
                        handleChange={(e) => setPassword(e.target.value)}
                    />
                    <button className="w-100 btn btn-lg btn-primary" type="submit">เข้าสู่ระบบ</button>
                    <p className="mt-5 mb-3 text-center text-muted"><small>Copyright © 2022 <a href="/">DooSlip</a>. All rights reserved.</small></p>
                </form>
            </SignIn>
        </Wrapper>
    )
}

export default Login

const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #feeff1;
`;

const SignIn = styled.main`
	width: 100%;
	max-width: 400px;
	padding: 15px;

	a {
		color: #fa70a2;
	}
`;
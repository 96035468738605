import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from './../../assets/img/logo.png'
import './../../assets/scss/fontawesome.scss'
import './../../assets/scss/sidebar.scss'
// import useAuth from '../../utils/Auth_'
// import ls from 'localstorage-slim'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

const Sidebar = () => {
  const { allows } = useSelector((state: ApplicationState) => state.auth)
  const currentLocation = useLocation().pathname;
  return (
    <>
      <div className="sidebar">
        <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto">
          <img src={logo} className="sidebar-logo" alt="logo DooSlip" />
        </a>
        <ul className="nav nav-pills flex-column mb-auto">
          {Object.values(allows).find((item: any) => item === "USER_PAGE") &&
            <li>
              <Link to="/users"
                className={"nav-link link-dark" + (currentLocation === '/users' ? ' active' : '')}
              >
                <i className="fas fa-user"></i>
                <span>ผู้ใช้งาน</span>
              </Link>
            </li>
          }
          {Object.values(allows).find((item: any) => item === "ACCOUNT_PAGE") &&
            <li>
              <Link to="/account-group"
                className={"nav-link link-dark" + (currentLocation === '/account-group' ? ' active' : '')}
              >
                <i className="far fa-university"></i>
                <span>กลุ่มบัญชีธนาคาร</span>
              </Link>
            </li>
          }
          {Object.values(allows).find((item: any) => item === "ACCOUNT_PAGE") &&
            <li>
              <Link to="/accounts"
                className={"nav-link link-dark" + (currentLocation === '/accounts' ? ' active' : '')}
              >
                <i className="far fa-address-card"></i>
                <span>บัญชีธนาคาร</span>
              </Link>
            </li>
          }
          <li className="nav-item">
          </li>
        </ul>
        <div className="nav-pills">
          <a href="/logout" className="nav-link link-dark">
            <i className="fas fa-sign-out"></i>
            <span>ออกจากระบบ</span>
          </a>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
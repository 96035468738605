import React, { useState } from 'react'
import { Route, Routes, Navigate, Link } from 'react-router-dom'
import logo from './../assets/img/logo.png'
import Sidebar from '../components/Elements/Sidebar'
import { Menu, Dropdown } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import PasswordUser from '../components/Profile/PasswordUser'
import AuthInitContainer from '../components/Container/AuthInitContainer'

import ScrollToTop from '../utils/ScrollToTop'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import Users from '../views/Users';
import AccountG from '../views/AccountG';
import Accounts from '../views/Accounts';

const AppLayout = () => {
    const { allows, data } = useSelector((state: ApplicationState) => state.auth)
    const [passwordDrawer, setPasswordDrawer] = useState(false)
    const togglePasswordUser = () => setPasswordDrawer(!passwordDrawer)

    const toggleNavbar = () => {
        document.querySelector('.sos-nav-toggle .fal.fa-bars')?.classList.toggle('open');
        document.querySelector('.sos-nav-toggle .fal.fa-times')?.classList.toggle('open');
        document.querySelector('.app')?.classList.toggle('toggle');
    }


    const menu = (
        <Menu>
            <Menu.Item key="0">
                <Link to="/profile">ข้อมูลผู้ใช้งาน</Link>
            </Menu.Item>
            <Menu.Item key="1">
                <Link to="/security">ความปลอดภัย 2FA</Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Link to="#" onClick={() => {
                    togglePasswordUser()
                }}>เปลี่ยนรหัสผ่าน</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3" className="text-danger"><Link to="/logout"><i className="fas fa-sign-out"></i> ออกจากระบบ</Link></Menu.Item>
        </Menu>
    );

    return (
        <AuthInitContainer>
            <ScrollToTop />
            <div className="app">
                <Sidebar />
                <div className="content">
                    <nav className="navbar sticky-top navbar-light bg-white">
                        <div className="container">
                            <div className="sos-nav-toggle d-lg-none" onClick={() => toggleNavbar()}>
                                <i className="fal fa-bars open"></i>
                                <i className="fal fa-times"></i>
                            </div>
                            <a href="/" className="d-flex d-lg-none align-items-center mx-auto ">
                                <img src={logo} className="sos-nav-logo" alt="logo DooSlip" />
                            </a>
                            <div className="name-system">ระบบตรวจสอบสลีป</div>
                            <div className="ms-lg-auto">
                                <Dropdown overlay={menu}>
                                    <Profile className="ant-dropdown-link">
                                        <div className="d-lg-none">
                                            <UserOutlined style={{ padding: '4px 8px' }} />
                                        </div>
                                        <div className="d-none d-lg-block">
                                            <div className="profile-username">{Object.values(data).length > 0 && data?.first_name}</div>
                                            {Object.values(data).length > 0 && <small className="profile-permission">{data?.role?.charAt(0).toUpperCase() + data?.role?.slice(1)}</small>}
                                        </div>
                                        <DownOutlined className="d-none d-lg-block" style={{ fontSize: '16px', marginLeft: '8px' }} />
                                    </Profile>
                                </Dropdown>
                            </div>
                        </div>
                    </nav>
                    <div className="container">
                        <Routes>
                            {Object.values(allows).find((item: any) => item === "USER_PAGE") &&
                                <Route path="/users" element={<Users />} />
                            }
                            {Object.values(allows).find((item: any) => item === "ACCOUNT_PAGE") &&
                                <Route path="/account-group" element={<AccountG />} />
                            }
                            {/* {Object.values(allows).find((item: any) => item === "ACCOUNT_PAGE") && */}
                                <Route path="/accounts" element={<Accounts />} />
                            {/* } */}
                            <Route
                                path="*"
                                element={<Navigate to="/users" replace />}
                            />
                        </Routes>
                    </div>
                    <footer className="bg-white py-4">
                        <div className="container">
                            <div className="d-lg-flex text-center text-lg-right text-muted">
                                <p className="mb-0">Copyright © 2022 <a href="/" className="text-primary">DooSlip</a>. All rights reserved.</p>
                                <p className="ms-auto mb-0">DooSlip Management.</p>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
            {Object.values(data).length > 0 &&
                <PasswordUser
                    user={data as any}
                    isOpenPasswordDrawer={passwordDrawer}
                    closePasswordDrawer={() => togglePasswordUser()}
                />
            }
        </AuthInitContainer>
    )
}

export default AppLayout


const Profile = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.25;

    .profile-username {
        font-weight: bold;
        color: #fa70a2;
    }

    .profile-permission {
        color: #909090;
    }
`
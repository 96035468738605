import React, { FC, useEffect, useState } from 'react'
import { 
    Drawer, 
    Button, 
    Space,
    Divider, 
    Descriptions,} from 'antd';

interface ViewUserProps {
    user: any
	closeViewDrawer: () => void
	isOpenViewDrawer: boolean
	swapDrawerEdit: () => void
}

const ViewUser: FC<ViewUserProps> = ({ closeViewDrawer, isOpenViewDrawer, swapDrawerEdit, user }) => {
    const nameTitle = "ข้อมูลผู้ใช้งาน"
    const [statusShow, setStatusShow] = useState('')
    useEffect(() => {
        const statusList: any = [{status: 'enable', name: 'เปิด'},{status: 'disable', name: 'ปิด'}]
        if (user) {
            setStatusShow(statusList.find((item: any) => {return item.status === user.status}).name)
        }
    }, [user])
	return (
        <>
        <Drawer
            title={nameTitle}
            onClose={closeViewDrawer}
            visible={isOpenViewDrawer}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
            <Space>
                <Button onClick={closeViewDrawer} size="large">ปิด</Button>
                <Button 
                    onClick={swapDrawerEdit} 
                    type="primary" 
                    size="large"
                    >
                    <i className="far fa-edit me-2"></i>
                    แก้ไข
                </Button>
            </Space>
            }
        >
            <Descriptions title={nameTitle} column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="ชื่อ - นามสกุล">{user.first_name}</Descriptions.Item>
                <Descriptions.Item label="ชื่อผู้ใช้งาน">{user.username}</Descriptions.Item>
                <Descriptions.Item label="สิทธิ์">{user.role ? user.role.charAt(0).toUpperCase() + user.role.slice(1) : '-'}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="ข้อมูลระบบ" column={1} labelStyle={{justifyContent: 'flex-end',minWidth:100}}>
                <Descriptions.Item label="สถานะ">{statusShow}</Descriptions.Item>
                <Descriptions.Item label="วันที่สร้าง">{user.createdAt}</Descriptions.Item>
                <Descriptions.Item label="สร้างโดย">{user.created_user_name}</Descriptions.Item>
                <Descriptions.Item label="วันที่แก้ไข">{user.updatedAt}</Descriptions.Item>
                <Descriptions.Item label="แก้ไขโดย">{user.updated_user_name}</Descriptions.Item>
            </Descriptions>
            
        </Drawer>
        </>
	)
}
export default ViewUser

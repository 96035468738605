import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import AppLayout from './layouts/App'
// import Auth from './layouts/Auth'
import './app.scss'
// import useAuth from './utils/Auth'
import 'antd/dist/antd.css'
import 'sweetalert2/src/sweetalert2.scss'
// import ls from 'localstorage-slim'
import Login from './views/Login'
import Logout from './views/Logout'

function App() {
  // const { token, setToken, setMe, getMe } = useAuth();

  // if (!token) {
  //   return <Auth setToken={setToken} setMe={setMe} />
  // }

  // if (!getMe || !token) {
  //   ls.remove('jwt');
  //   ls.remove('me');
  //   window.location.reload();
  // }

  return (
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<AppLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

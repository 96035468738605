import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Select,
    Space,
    notification,
    Divider
} from 'antd';
import SendAPI from '../../utils/SendAPI'

interface EditAccountProps {
    accounts: any
    closeEditDrawer: () => void
    isOpenEditDrawer: boolean
    onloadData: () => void
}

interface EditAccountUpdateProps {
    id: number
    bank_name: string
    bank_account: string
    // encrypt: string
    status: string
    device_id?: string
    license?: string
    pin?: string
}

const EditAccount: FC<EditAccountProps> = ({ closeEditDrawer, isOpenEditDrawer, onloadData, accounts }) => {
    const nameTitle = "แก้ไขบัญชี"
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState(false)
    // const patternNumber: any = new RegExp("^[0-9]{6,6}$")

    const [dataAccountG, setDataAccountG] = useState<any>([])

    const UpdateAccount = async (values: EditAccountUpdateProps) => {
        const updateResponse: any = await sendAPIWithJWT({
            path: `/accounts/${accounts.id}`,
            method: "put",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (updateResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: updateResponse.data?.message
            });
            setBtnLoading(false)
            closeEditDrawer()
            form.resetFields()
            onloadData()
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: updateResponse.message ? updateResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
        }
    }

    const getAccountG = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/account-groups",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataAccountG(dataResponse.data?.data)
        }
        return dataResponse
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: EditAccountUpdateProps) => {
                setBtnLoading(true)
                UpdateAccount(values)
            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpenEditDrawer && accounts) {
            getAccountG()
            form.resetFields();
            form.setFieldsValue(accounts)
        }
    }, [form, isOpenEditDrawer, accounts]) // eslint-disable-line

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeEditDrawer}
                visible={isOpenEditDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeEditDrawer} size="large">ยกเลิก</Button>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large"
                        >
                            <i className="far fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="editAccount"
                    layout="vertical"
                    initialValues={accounts}
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="account_group_id"
                                label="กลุ่มบัญชีธนาคาร"
                                rules={[{ required: true, message: 'กรุณาเลือกกลุ่มบัญชีธนาคาร' }]}
                                hasFeedback
                            >
                                <Select placeholder="เลือกกลุ่มบัญชีธนาคาร">
                                    {dataAccountG &&
                                        dataAccountG.map((item: any, key: number) => (
                                            <Option value={item.id}>{item.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_code"
                                label="รหัสธนาคาร"
                                rules={[{ required: true, message: 'กรุณากรอกรหัสธนาคาร' }]}
                                hasFeedback
                            >
                                <Input placeholder="รหัสธนาคาร" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_name"
                                label="ชื่อบัญชี"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อบัญชี' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อบัญชี" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_account"
                                label="เลขที่บัญชี"
                                rules={[{ required: true, message: 'กรุณากรอกเลขที่บัญชี' }]}
                                hasFeedback
                            >
                                <Input placeholder="เลขที่บัญชี" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="สถานะ"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                                hasFeedback
                            >
                                <Select placeholder="เลือกสถานะ">
                                    <Option value="enable">เปิด</Option>
                                    <Option value="disable">ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-save me-2"></i> บันทึก</button>
                        <button type="button" className="btn btn-light me-3" onClick={() => closeEditDrawer()}>ยกเลิก</button>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditAccount

import React, { FC, useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

interface PasswordInputProps {
    password: string
	handleChange: (e: ChangeEvent<any>) => void
}

const PasswordInput: FC<PasswordInputProps> = ({ password, handleChange }) => {
    const [isPassword, setIsPassword] = useState(true);
    const togglePasswordInput = () => setIsPassword(!isPassword)

    return (
        <div className="form-floating mb-3">
            <input type={isPassword ? 'password' : 'text'} className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => handleChange(e)} value={password} required/>
            <label>รหัสผ่าน</label>
            <TogglePassword onClick={togglePasswordInput}>
                { isPassword ? <EyeOutlined style={{ fontSize: '24px' }}/> : <EyeInvisibleOutlined style={{ fontSize: '24px' }}/> }
            </TogglePassword>
        </div>
    )
}

export default PasswordInput

const TogglePassword = styled.div`
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    opacity: 0.5;
    transition: all 0.25s ease;

    &:hover {
        opacity: 1;
    }
`
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        document.getElementsByClassName("app")[0].scrollTo(0,0)
        document.getElementsByClassName("app")[0].classList.remove("toggle")
        document.querySelector('.sos-nav-toggle .fal.fa-bars')?.classList.add('open');
        document.querySelector('.sos-nav-toggle .fal.fa-times')?.classList.remove('open');
    }, [pathname]);

    return null;
}


import React, { Fragment, useEffect, useState } from 'react'
import AccountTable from '../../components/Accounts/AccountTable'
// import AccounstMockup from '../../mockup-json/AccountsMockup.json'
import SendAPI from '../../utils/SendAPI'
const Accounts = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataAccounts, setDataAccounts] = useState<any>([])
    const getAccounts = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/accounts",
            method : "get",
        });
        if (dataResponse.status === 200) {
            setDataAccounts(dataResponse.data?.data)
            setLoading(false)
        }
        return dataResponse
    }
    
    useEffect(() => {
        getAccounts()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <AccountTable list={dataAccounts} loading={loading} onloadData={getAccounts} />
            </div>
        </Fragment>
    )
}

export default Accounts
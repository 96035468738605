import React, { Fragment, useEffect, useState } from 'react'
import AccountGTable from '../../components/AccountG/Table'
// import AccounstMockup from '../../mockup-json/AccountsMockup.json'
import SendAPI from '../../utils/SendAPI'
const AccountG = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataAccountG, setDataAccountG] = useState<any>([])
    const getAccountG = async () => {

        const dataResponse: any = await sendAPIWithJWT({
            path: "/account-groups",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataAccountG(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }
    useEffect(() => {
        getAccountG()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <AccountGTable list={dataAccountG} loading={loading} onloadData={getAccountG} />
            </div>
        </Fragment>
    )
}

export default AccountG
import { AuthInitResponse, LoginParam, LoginResponse } from '../@types/auth'
import api from '../utils/api'

class AuthService {
	private token: string = ''

	constructor() {
		const token = localStorage.getItem('jwt')
		if (token) {
			this.setToken(token)
		}
	}

	getToken(): string {
		return this.token
	}
	setToken(token: string, save: boolean = false) {
		this.token = token
		if (save) {
			localStorage.setItem('jwt', token)
		}
	}
	getUsername(): string {
		const username = localStorage.getItem('username')
		return username || '-'
	}
	setUsername(username: string, save: boolean = false) {
		if (save) {
			localStorage.setItem('username', username)
		}
	}
	revokeToken() {
		this.token = ''

		delete api.defaults.headers.common['Authorization']
		localStorage.removeItem('jwt')
		localStorage.removeItem('username')
	}

	async login(data: LoginParam): Promise<boolean> {
		try {
			const { token } = await api.post<void, LoginResponse>(
				'/auth/login',
				data
			)

			this.setToken(token, true)
			this.setUsername(data.username, true)
			return false
		} catch (e) {
			return Promise.reject(e)
		}
	}

	async getAuthInit(): Promise<AuthInitResponse> {
		try {
			const res = await api.get<void, AuthInitResponse>('/auth/me')

			return res
		} catch (e) {
			return Promise.reject(e)
		}
	}
}

export default new AuthService()

import axios, { AxiosError } from 'axios'
import AuthService from '../services/auth'

const baseRequest = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
})

baseRequest.interceptors.request.use((config) => {
    const token = AuthService.getToken()

    return {
        ...config,
        headers: {
            ...config.headers,
            ...(token && { Authorization: `Bearer ${token}` }),
        },
    }
})

baseRequest.interceptors.response.use(
    (res) => res.data,
    (err: AxiosError) => {
        if (err.response) {
            return Promise.reject(err.response.data)
        }
        return Promise.reject({
            message: 'ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้',
        })
    }
)

export default baseRequest

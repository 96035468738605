import React, { FC, useState, useEffect } from 'react'
import {
    Drawer,
    Form,
    Button,
    Col,
    Row,
    Input,
    Select,
    Space,
    notification
} from 'antd';
import SendAPI from '../../utils/SendAPI'

interface EditUserProps {
    user: any
    closeEditDrawer: () => void
    isOpenEditDrawer: boolean
    onloadData: () => void
}
interface EditUserUpdateProps {
    id: number
    username: string
    first_name: string
    role: string
    status: string
}

const EditUser: FC<EditUserProps> = ({ closeEditDrawer, isOpenEditDrawer, onloadData, user }) => {
    const nameTitle = "แก้ไขผู้ใช้งาน"
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState(false)

    const [dataAccountG, setDataAccountG] = useState<any>([])

    const getAccountG = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/account-groups",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataAccountG(dataResponse.data?.data)
        }
        return dataResponse
    }

    const UpdateUser = async (values: EditUserUpdateProps) => {
        const updateUserResponse: any = await sendAPIWithJWT({
            path: `/users/${user.id}`,
            method: "put",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (updateUserResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: updateUserResponse.data?.message
            });
            setBtnLoading(false)
            closeEditDrawer()
            form.resetFields()
            onloadData()
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: updateUserResponse.message ? updateUserResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
        }

    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: EditUserUpdateProps) => {
                setBtnLoading(true)
                UpdateUser(values)

            })
            .catch((errorInfo) => { });
    };

    useEffect(() => {
        if (form && isOpenEditDrawer && user) {
            getAccountG()
            form.resetFields();
            form.setFieldsValue(user)
        }
    }, [form, isOpenEditDrawer, user]) // eslint-disable-line

    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeEditDrawer}
                visible={isOpenEditDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeEditDrawer}
                            size="large">ยกเลิก</Button>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large"
                        >
                            <i className="far fa-save me-2"></i>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="editUser"
                    layout="vertical"
                    initialValues={user}
                    hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="account_group_id"
                                label="กลุ่มบัญชีธนาคาร"
                                rules={[{ required: true, message: 'กรุณาเลือกกลุ่มบัญชีธนาคาร' }]}
                                hasFeedback
                            >
                                <Select placeholder="เลือกกลุ่มบัญชีธนาคาร">
                                    {dataAccountG &&
                                        dataAccountG.map((item: any, key: number) => (
                                            <Option value={item.id}>{item.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="first_name"
                                label="ชื่อ"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อ" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="last_name"
                                label="นามสกุล"
                                rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
                                hasFeedback
                            >
                                <Input placeholder="นามสกุล" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="username"
                                label="ชื่อผู้ใช้งาน"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อผู้ใช้งาน' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อผู้ใช้งาน" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="role"
                                label="สิทธิ์"
                                rules={[{ required: true, message: 'กรุณาเลือกสิทธิ์' }]}
                                hasFeedback
                            >
                                <Select placeholder="เลือกสิทธิ์">
                                    <Option value="master">Master</Option>
                                    <Option value="admin">Admin</Option>
                                    <Option value="staff">Staff</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="สถานะ"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                                hasFeedback
                            >
                                <Select placeholder="เลือกสถานะ">
                                    <Option value="enable">เปิด</Option>
                                    <Option value="disable">ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-save me-2"></i> บันทึก</button>
                        <button type="button" className="btn btn-light me-3" onClick={() => closeEditDrawer()}>ยกเลิก</button>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default EditUser

import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { AnyAction } from 'redux'
import styled from 'styled-components'
import { ThunkDispatch } from '../../@types/store'
import { Colors } from '../../utils/color'
import { ApplicationState } from '../../store'
import { requestAuthInit } from '../../store/auth/auth.actions'


const AuthInitContainer: FC = ({ children }) => {
	const dispatch: ThunkDispatch<AnyAction> = useDispatch()
	const authState = useSelector((state: ApplicationState) => state.auth)

	useEffect(() => {
		dispatch(requestAuthInit(true))
	}, [dispatch])

	const authLoading = !authState.init || authState.loading
	if (authLoading) {
		return (
			<CenterContainer className="app">
				<i className="fa-solid fa-spinner fa-spin fa-2x" />
			</CenterContainer>
		)
	}
	if (authState.error) {
		return <Navigate to="/login" replace />
	}
	return <>{children}</>
}

export default AuthInitContainer

const CenterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;

	h4 {
		color: ${Colors.negative};
		font-family: 'Sarabun, sans-serif';
	}
`
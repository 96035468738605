import React, { Fragment, useEffect, useState } from 'react'
import UserTable from '../../components/Users/UserTable'
// import UsersMockup from '../../mockup-json/UsersMockup.json'
import SendAPI from '../../utils/SendAPI'
const Users = () => {
	const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataUser, setDataUser] = useState<any>([])
    const getUsers = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/users",
            method : "get",
        });
        if (dataResponse.status === 200) {
            setDataUser(dataResponse.data?.data)
        }
        setLoading(false)
        return dataResponse
    }
    
    useEffect(() => {
        getUsers()
    }, [])// eslint-disable-line
    
    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
            <UserTable list={dataUser} loading={loading} onloadData={getUsers}/>
            </div>
        </Fragment>
    )
}

export default Users
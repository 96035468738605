import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
// import DataTable from 'react-data-table-component'
import CreateData from './Create'
import EditData from './Edit'
import ViewData from './View'
import { Input, Table, Switch, notification, Space} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Swal from 'sweetalert2'
import SendAPI from '../../utils/SendAPI'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'
// import _ from 'lodash';

interface DepartmentTableProps {
    list: any
    loading: boolean
	onloadData: () => void
}

const DepartmentTable: FC<DepartmentTableProps> = ({ list, loading, onloadData }) => {
	const { sendAPIWithJWT } = SendAPI();
    const [filterText, setFilterText] = useState('')
	// const [resetPaginationToggle] = useState(false)
    
    const [dataOjects, setDataOjects] = useState<any>([])

    const [createDataDrawer, setCreateDataDrawer] = useState(false)
    const [editDataDrawer, setEditDataDrawer] = useState(false)
    const [viewDataDrawer, setViewDataDrawer] = useState(false)
    const toggleCreateData = () => setCreateDataDrawer(!createDataDrawer)
    const toggleEditData = () => setEditDataDrawer(!editDataDrawer)
    const toggleViewData = () => setViewDataDrawer(!viewDataDrawer)

    const [switchLoading, setSwitchLoading] = useState(false)
    const [department, setDepartment] = useState('')
    const handleEditData = (object: any) => setDepartment(object)
    const handleDeleteData = (id: number) => {
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: "คุณต้องการลบรายการนี้ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dataResponse: any = await sendAPIWithJWT({
                    path : `/account-groups/${id}`,
                    method : "delete",
                });
                if (dataResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        html: dataResponse.data?.message,
                        timer: 2000,
                        timerProgressBar: true})
                        onloadData()
                } else {
                    Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
                }
            }
        })
    };

    const handleStatusData = async (status: boolean, id: number) => {
        if (id) {
            let statusUpdate = status ? "enable" : "disable"
            const dataResponse: any = await sendAPIWithJWT({
                path : `/account-groups/${id}`,
                method : "patch",
                headers : {'Content-Type': 'application/json'},
                data : {
                    "status": statusUpdate
                }
            });
            if (dataResponse.status === 200) {
                notification.success({
                    message: 'Success',
                    description: dataResponse.data?.message
                });
                list[list.findIndex((el: any) => el.id === id)].status = dataResponse.data?.data.status
            }else{
                notification.error({
                    message: 'Error',
                    description: dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด"
                });
            }
            setSwitchLoading(false)
        }
    };

    const columns = [
        {
            title: '#',
            render: (row: any) => row.serial,
            width: '72px',
            defaultSortOrder: 'ascend',
            sorter: (a:any, b:any) => a.serial - b.serial,
        },
        {
            title: 'ชื่อ Company',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div
                            className="btn-link-view"
                            onClick={() => {
                                handleEditData(row)
                                toggleViewData()
                            }}
                        >
                            {row.name}
                        </div>
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'สถานะ',
            width: '10%',
            render: (row: any) => {
                let switchStatus = (row.status === "enable") ? true : false
                return (
                    <>
                        <Switch
                            checkedChildren="เปิด"
                            unCheckedChildren="ปิด"
                            checked={switchStatus}
                            loading={switchLoading}
                            onChange={(e) =>{
                                setSwitchLoading(true)
                                handleStatusData(e, row.id)
                            }}
                        />
                    </>
                )
            },
        },
        {
            title: 'วันที่สร้าง',
            render: (row: any) => row.createdAt,
        },
        {
            title: 'จัดการ',
            render: (row: any) => {
                return (
                    <Space size={0}>
                        <button
                            className="btn btn-warning btn-sm text-white me-2"
                            onClick={() => {
                                handleEditData(row)
                                toggleEditData()
                            }}
                        >
                            แก้ไข
                        </button>
                        <button className="btn btn-danger btn-sm" onClick={() => {handleDeleteData(row.id)}}>ลบ</button>
                    </Space>
                )
            },
        }
    ]

    useEffect(() => {
        if (Object.keys(list).length > 0) {
            const filteredItems = (list as any).filter(
                (item: any) => (
                    (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) || 
                    (item.code && item.code.toLowerCase().includes(filterText.toLowerCase()))
                ),
            );
            filteredItems.forEach((item: any, index: number) => { item.serial = index + 1; });
            setDataOjects(filteredItems)
        }
    }, [list, filterText, setDataOjects])

    return (
        <>
            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <div className="card-head">
                    <div className="row mb-md-3 align-items-md-center">
                        <div className="col-6 col-md-auto d-flex align-items-center">
                            <h2>กลุ่มบัญชีธนาคาร</h2>
                        </div>
                        <div className="col-6 col-md-auto ms-md-auto">
                            <button
                                className="btn btn-primary mb-3 mb-md-0"
                                onClick={() => toggleCreateData()}
                            >
                                <i className="far fa-plus me-3"></i>
                                <span>เพิ่ม กลุ่มบัญชีธนาคาร</span>
                            </button>
                        </div>
                    </div>
                    <div className="ms-md-auto filter-wrapper">
                        <div className="filter-group">
                            <Input
                                size="large"
                                addonBefore={<SearchOutlined style={{ marginBottom: '6px' }}/>}
                                allowClear
                                placeholder="ค้นหา"
                                value={filterText ?? ''}
                                onChange={(e: any) => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns as any}
                    dataSource={dataOjects}
                    rowKey={record => record.id}
                    loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: 57 }}
                    onChange={ScrollToTopPagination}
                    pagination={{ 
                        defaultPageSize: 20, 
                        showSizeChanger: true, 
                        pageSizeOptions: ['10', '20', '30', '40', '50', '80', '100'], 
                        position: ['topRight', 'bottomRight'],
                        locale:{items_per_page: " รายการ"}
                    }}
                />
            </ListTableCard>

            <CreateData
	            isOpenCreateData={createDataDrawer}
                closeCreateData={() => toggleCreateData()}
                onloadData={() => onloadData()}
            />

            <EditData
                listData={department as any}
	            isOpenEditData={editDataDrawer}
                closeEditData={() => toggleEditData()}
                onloadData={() => onloadData()}
            />

            <ViewData
                listData={department as any}
	            isOpenViewData={viewDataDrawer}
                closeViewData={() => toggleViewData()}
                swapDataEdit={() => {
                    toggleViewData()
                    toggleEditData()
                }}
            />
        </>
	);
}

export default DepartmentTable

const ListTableCard = styled.div`
    background: #fff;
    border-radius: 16px;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`

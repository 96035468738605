import axios from 'axios'
import dotenv from 'dotenv'
// import useAuth from './Auth'
import AuthService from '../services/auth'
// import ls from 'localstorage-slim'
dotenv.config();
interface SendProps {
    path: string
    method: any
    headers?: any
    data?: any

}
// interface dataSendMe {
//     path: string
//     method: any
//     headers?: any
//     data?: any
//     token: any

// }

export default function SendAPI() {
    // const { token } = useAuth();

    const token = AuthService.getToken()
    const url = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : "http://localhost:5000"

    const sendApiAuth = async (dataSend: SendProps) => {
        const { path, headers, data, method } = dataSend
        let setHeader: any = {}
        setHeader = headers ? headers : {}
        const dataResp = await axios({
            method: method,
            url: `${url}${path}`,
            headers: setHeader,
            data: data
        })
            .then(function (response: any) {
                return {
                    status: response.status,
                    message: response.data?.message,
                    data: response.data?.token
                }
            })
            .catch(function (error: any) {
                return {
                    status: error.response.status,
                    message: error.response.data?.message
                }
            });
        return dataResp
    }

    const sendAPI = async (dataSend: SendProps) => {
        const { path, headers, data, method } = dataSend
        let setHeader: any = {}
        setHeader = headers ? headers : {}
        const dataResp = await axios({
            method: method,
            url: `${url}${path}`,
            headers: setHeader,
            data: data
        })
            .then(function (response: any) {
                return {
                    status: response.status,
                    statusText: response.statusText,
                    message: "Success",
                    data: response.data
                }
            })
            .catch(function (error: any) {
                if (error.response.status === 500) {
                    // localStorage.removeItem('jwt');
                    // localStorage.removeItem('me');
                    // ls.remove('jwt');
                    // ls.remove('me');

                    AuthService.revokeToken()
                    window.location.reload();
                }
                return {
                    status: error.response.status,
                    statusText: error.response.statusText,
                    message: error.response.data?.message,
                    data: []
                }
            });
        return dataResp
    }

    const sendAPIWithJWT = async (dataSend: SendProps) => {
        const { path, headers, data, method } = dataSend
        let setHeader: any = {}
        setHeader = headers ? headers : {}
        if (!token) {
            return null
        }
        setHeader.Authorization = `Bearer ${token}`
        const dataResp = await axios({
            method: method,
            url: `${url}${path}`,
            headers: setHeader,
            data: data
        })
            .then(function (response: any) {
                return {
                    status: response.status,
                    statusText: response.statusText,
                    message: "Success",
                    data: response.data
                }
            })
            .catch(function (error: any) {
                if (error.response.status === 500) {
                    // localStorage.removeItem('jwt');
                    // localStorage.removeItem('me');
                    // ls.remove('jwt');
                    // ls.remove('me');
                    AuthService.revokeToken()
                    window.location.reload();
                }
                return {
                    status: error.response.status,
                    statusText: error.response.statusText,
                    message: error.response.data?.message ? error.response.data?.message : (error.response.data?.data ? error.response.data?.data : ''),
                    data: null
                }
            });
        return dataResp
    }



    return {
        sendAPI,
        sendAPIWithJWT,
        sendApiAuth
    }
}
